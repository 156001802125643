<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-4"
        :label="$t('modulos.tipo_comentario.formulario.nome')"
        obrigatorio
        :max="150"
        trim
      />

      <input-file
        returns="base64"
        class="col-12 col-md-4"
        :placeholder="$t('modulos.tipo_comentario.formulario.add_imagem')"
        :image="form.imagem"
        @nome-imagem="nomeImagem"
        @handle-upload="handleUpload"
      />

      <input-text
        v-model="form.nomeExtensaoImagem"
        class="col-12 col-md-4"
        :label="$t('modulos.tipo_comentario.formulario.nome_extensao_imagem')"
        disabled
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import TipoComentarioOrcamentoService from '@common/services/cadastros/TipoComentarioOrcamentoService';
import { TipoComentarioModel } from '@common/models/cadastros/TipoComentarioModel';
import { InputFile } from '@components/inputs';
import helpers from '@common/utils/helpers';
export default {
  components: {
    InputFile,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new TipoComentarioModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.tipo_comentario.titulos.editar');
      return this.$t('modulos.tipo_comentario.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(
        this,
        'TipoComentarioOrcamento',
        'Editar'
      );
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(
      this,
      'TipoComentarioOrcamento',
      'Inserir'
    );

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoComentarioOrcamentoService.buscar(this.id)
        .then((res) => {
          this.form = new TipoComentarioModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.tipo_comentario.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoComentarioOrcamentoService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'tipo-comentario' });
          this.toastSucesso(
            this.$t(`modulos.tipo_comentario.cadastro_sucesso`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'tipo-comentario' });
      });
    },
    handleUpload: function (file) {
      this.form.imagem = file;
    },
    nomeImagem: function (nomeImagem) {
      this.form.nomeExtensaoImagem = nomeImagem;
    },
  },
};
</script>
